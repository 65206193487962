import {
  StarBorderRounded,
  StarRounded,
  VisibilityOff,
} from '@mui/icons-material';
import { Box, IconButton, Stack } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomCard from '../../common/CustomCard';
import { ReactComponent as DealValue } from '../../common/icons/request_quote.svg';
import { ReactComponent as Teams } from '../../common/icons/teams.svg';
import Logo from '../../common/Logo';
import StyledIcon from '../../common/StyledIcon';
import SupplierBadges from '../../common/SupplierBadges.js';
import { formatCurrency } from '../../utils/functions.js';

export const SupplierAlternativeVote = ({
  supplier,
  handleStar,
  handleHide,
  isExternal,
}) => {
  const supplierId = isExternal ? supplier.id : supplier.internal_uuid;
  return (
    <Stack
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'flex-end'}
      width={'30px'}
    >
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <IconButton
          type="button"
          sx={{ padding: '8px' }}
          onClick={(e) => {
            handleStar(supplierId, isExternal);
            e.stopPropagation();
          }}
        >
          {supplier.starred ? (
            <StyledIcon component={StarRounded} color={'#F7CD34'} />
          ) : (
            <StyledIcon component={StarBorderRounded} color={'icon'} />
          )}
        </IconButton>
        {Boolean(!supplier.starred) && (
          <IconButton
            type="button"
            sx={{ padding: '8px' }}
            onClick={(e) => {
              handleHide(supplierId, isExternal);
              e.stopPropagation();
            }}
          >
            <StyledIcon
              component={VisibilityOff}
              color={supplier.hidden ? 'icon_error' : 'icon'}
            />
          </IconButton>
        )}
      </Box>
    </Stack>
  );
};

const SupplierAlternativeItem = ({
  supplier,
  sidebar,
  handleStar,
  handleHide,
}) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const [categories, setCategories] = useState([]);
  const [teams, setTeams] = useState([]);
  const [stakeholder, setStakeholder] = useState([]);
  const { page } = useSelector((state) => state.appSlice);

  useEffect(() => {
    const teams = [
      ...(supplier?.financial_stats?.departments || []),
      ...(supplier?.workflow_stats?.departments || []),
    ];
    const stakeholderTeam =
      supplier?.financial_stats?.most_active_stakeholder?.split(',')[1] ||
      supplier?.workflow_stats?.most_active_stakeholder?.split(',')[1];

    setCategories(supplier?.categories_ai || supplier?.categories || []);
    setTeams(teams);
    setStakeholder(stakeholderTeam);
  }, [supplier]);

  const detailsLink =
  page === 'overview'
    ? `/details/supplier/${supplier.id}${sidebar ? '' : '/fullscreen'}`
    : `/${page}/details/supplier/${supplier.id}${
        sidebar ? '' : '/fullscreen'
      }${search.includes('alternativeType=internal') ? '' : search}`;

  return (
    <CustomCard isHover={true} onClick={() => navigate(detailsLink)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '16px',
          gap: '16px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              alignItems: 'center',
              width: 'calc(100% - 30px)',
            }}
          >
            <Logo size={54} img={supplier.image_url} />
            <Box display={'flex'} flexDirection={'column'} gap={'2px'}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                gap={'4px'}
                alignItems={'center'}
              >
                <Box
                  color={(theme) => theme.palette.text.main}
                  sx={{
                    lineHeight: '150%',
                  }}
                  fontSize={(theme) => theme.typography.subtitle1}
                >
                  {supplier.name}
                </Box>
                <SupplierBadges
                  supplier={supplier}
                  gap={'4px'}
                  manualActiveInteraction
                  iconOnly
                />
              </Box>
              {Boolean(supplier.onboarded_at) && (
                <Box
                  fontSize={(theme) => theme.typography.body2}
                  color={(theme) => theme.palette.text.medium}
                >
                  {`Supplier since ${moment(supplier.onboarded_at).format(
                    'MMM DD, YYYY',
                  )}`}
                </Box>
              )}
            </Box>
          </Box>
          <SupplierAlternativeVote
            supplier={supplier}
            handleStar={handleStar}
            handleHide={handleHide}
          />
        </Box>
        {Boolean(categories.length) && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: '10px',
              maxHeight: '60px',
              overflow: 'hidden',
              width: '100%',
            }}
          >
            {categories.map((item, index) => (
              <Box
                key={`category-${index}`}
                sx={{
                  border: '1px solid rgba(2, 136, 209, 0.5)',
                  borderRadius: '100px',
                  padding: '4px 10px',
                  fontSize: '13px',
                  color: '#0288D1',
                }}
              >
                {item}
              </Box>
            ))}
          </Box>
        )}
      </Box>
      <Box sx={{ minHeight: '50px', padding: '16px', display: 'flex' }}>
        <Box
          sx={{
            width: 'calc(100% / 2)',
          }}
        >
          <Box
            fontSize={(theme) => theme.typography.body1}
            color={(theme) => theme.palette.text.main}
            sx={{
              marginBottom: '8px',
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
            }}
          >
            <DealValue />
            <Box sx={{ display: 'flex', gap: '4px', flexWrap: 'wrap' }}>
              <Box color={(theme) => theme.palette.text.main}>
                {formatCurrency(supplier.total_spend, true)}
              </Box>
            </Box>
          </Box>
          <Box fontSize={(theme) => theme.typography.body2}>
            <Box
              component={'span'}
              color={(theme) => theme.palette.text.medium}
            >
              Last 12 month spend
            </Box>
          </Box>
        </Box>
        <Box
          color={(theme) => theme.palette.text.medium}
          fontSize={(theme) => theme.typography.body2}
          sx={{
            width: 'calc(100% / 2)',
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            paddingRight: '8px',
          }}
        >
          {Boolean(teams.length) && (
            <>
              <Box
                color={(theme) => theme.palette.text.main}
                fontSize={(theme) => theme.typography.body2}
                sx={{
                  display: 'flex',
                  gap: '8px',
                  alignItems: 'flex-start',
                }}
              >
                <Box>
                  <Teams />
                </Box>
                <Box
                  sx={{ display: 'flex', gap: '4px', flexWrap: 'wrap' }}
                  fontSize={(theme) => theme.typography.body1}
                >
                  {teams?.slice(0, 3).join(', ')}
                </Box>
              </Box>
              {Boolean(stakeholder) && (
                <Box color={(theme) => theme.palette.text.medium}>
                  Most active:{' '}
                  <Box component={'span'} sx={{ mr: 4 }}>
                    <Box
                      component={'span'}
                      color={(theme) => theme.palette.text.main}
                    >
                      {stakeholder}
                    </Box>
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </CustomCard>
  );
};

export default SupplierAlternativeItem;
