import { Tab, Tabs } from '@mui/material';
import React from 'react';

export const BACKOFFICE_TABS = {
  INTERACTIONS: 'interactions',
  BUNDLES: 'bundles',
  CONTRACTS: 'contracts',
  ALTERNATIVES: 'alternatives',
};

const tabStyle = {
  fontSize: '18px',
  fontWeight: '500',
  opacity: '1',
  padding: '14px 0',
  height: '40px',
  marginRight: '20px',
  textTransform: 'none',
  span: { background: 'transparent' },
};

const BackofficeTabs = ({ currentTab, handleChangeTab }) => {
  return (
    <Tabs
      value={currentTab}
      textColor={'inherit'}
      sx={{ height: '100%' }}
      TabIndicatorProps={{
        sx: {
          color: 'secondary',
        },
      }}
      indicatorColor={'secondary'}
      onChange={handleChangeTab}
    >
      <Tab
        label="Interactions"
        value={BACKOFFICE_TABS.INTERACTIONS}
        sx={{
          color:
            currentTab === BACKOFFICE_TABS.INTERACTIONS
              ? '#694FA3'
              : '#00000099',
          ...tabStyle,
        }}
      />
      <Tab
        label="Bundles"
        value={BACKOFFICE_TABS.BUNDLES}
        sx={{
          color:
            currentTab === BACKOFFICE_TABS.BUNDLES ? '#694FA3' : '#00000099',
            ...tabStyle,
          }}
      />
      <Tab
        label="Contracts"
        value={BACKOFFICE_TABS.CONTRACTS}
        sx={{
          color:
            currentTab === BACKOFFICE_TABS.CONTRACTS ? '#694FA3' : '#00000099',
            ...tabStyle,
          }}
      />
      <Tab
        label="External alternatives"
        value={BACKOFFICE_TABS.ALTERNATIVES}
        sx={{
          color:
            currentTab === BACKOFFICE_TABS.ALTERNATIVES
              ? '#694FA3'
              : '#00000099',
          ...tabStyle,
        }}
      />
    </Tabs>
  );
};

export default BackofficeTabs;
