export const findSupplierHierarchy = (supplierId, localTimeframe) => {
  const supplier = JSON.parse(JSON.stringify(supplierHierarchyDemoData[supplierId] || {}));
  localTimeframe = Number(localTimeframe) || 12;

  if (supplier.childrenFormatted && supplier.childrenFormatted.length > 0) {
    supplier.childrenFormatted = supplier.childrenFormatted.map((child) => {
      child.accounts = child.accounts.map((account) => {
        return {
          ...account,
          value: account.value ? account.value[localTimeframe] : 0,
        };
      });
      return child;
    });
  }

  if (supplier.parentFormatted) {
    supplier.parentFormatted.accounts = supplier.parentFormatted.accounts.map(
      (account) => {
        return {
          ...account,
          value: account.value ? account.value[localTimeframe] : 0,
        };
      },
    );
  }

  return supplier;
};

const supplierHierarchyDemoData = {
  '71e54e38-e0b9-4239-bea7-b28e7c062c0d': {
    // Microsoft
    parent: null,
    children: [
      'b02e804a-1e7e-48e1-bb92-52e9f6e5f6c4',
      'b62eacaa-d353-41b8-ac3f-15dfd321d854',
    ],
    childrenFormatted: [
      {
        name: 'Github',
        id: 'b02e804a-1e7e-48e1-bb92-52e9f6e5f6c4',
        accounts: [
          {
            name: 'Github Inc.',
            value: {
              6: 0,
              12: 323337.09,
              60: 329300.2,
              max: 329300.2,
            },
            description:
              'GitHub provides a subscription service for GitHub Enterprise Cloud, including user seats and additional data packs for Git Large File Storage (LFS).',
            categories: ['Software Subscription', 'Data Storage'],
          },
        ],
      },
      {
        name: 'LinkedIn',
        id: 'b62eacaa-d353-41b8-ac3f-15dfd321d854',
        accounts: [
          {
            name: 'LINKEDIN',
            value: {
              6: 180829.65,
              12: 752877,
              60: 1310651.88,
              max: 1310651.88,
            },
            description:
              'LinkedIn is used for various marketing and advertising activities, including lead generation campaigns with sponsored content and job ads.',
            categories: ['Lead Generation', 'Sponsored Content Ads', 'Job Ads'],
          },
          {
            name: 'LinkedIn Ireland Unlimited Company',
            value: {
              6: 32211.35,
              12: 32211.35,
              60: 33626.19,
              max: 33626.19,
            },
            description:
              'LinkedIn is used for advertising campaigns and the Sales Navigator software.',
            categories: ['Advertising Campaigns', 'Sales Software'],
          },
        ],
      },
    ],
    siblings: [],
    supplierIds: [
      'b02e804a-1e7e-48e1-bb92-52e9f6e5f6c4',
      'b62eacaa-d353-41b8-ac3f-15dfd321d854',
    ],
  },
  'b02e804a-1e7e-48e1-bb92-52e9f6e5f6c4': {
    // Github
    parent: '71e54e38-e0b9-4239-bea7-b28e7c062c0d',
    parentFormatted: {
      name: 'Microsoft',
      id: '71e54e38-e0b9-4239-bea7-b28e7c062c0d',
      accounts: [],
    },
    children: [],
    siblings: ['b62eacaa-d353-41b8-ac3f-15dfd321d854'],
    supplierIds: [
      '71e54e38-e0b9-4239-bea7-b28e7c062c0d',
      'b62eacaa-d353-41b8-ac3f-15dfd321d854',
    ],
  },
  'b62eacaa-d353-41b8-ac3f-15dfd321d854': {
    // Linkedin
    parent: '71e54e38-e0b9-4239-bea7-b28e7c062c0d',
    parentFormatted: {
      name: 'Microsoft',
      id: '71e54e38-e0b9-4239-bea7-b28e7c062c0d',
      accounts: [],
    },
    children: [],
    siblings: ['b02e804a-1e7e-48e1-bb92-52e9f6e5f6c4'],
    supplierIds: [
      '71e54e38-e0b9-4239-bea7-b28e7c062c0d',
      'b02e804a-1e7e-48e1-bb92-52e9f6e5f6c4',
    ],
  },
  '7c637048-99f5-4899-8dc2-97b13d541a74': {
    // Salesforce
    parent: null,
    children: ['33f7ebb7-5e9d-4af9-aff9-30e7d89ceb63'],
    childrenFormatted: [
      {
        name: 'Slack',
        id: '33f7ebb7-5e9d-4af9-aff9-30e7d89ceb63',
        accounts: [],
      },
    ],
    siblings: [],
    supplierIds: ['33f7ebb7-5e9d-4af9-aff9-30e7d89ceb63'],
  },
  '33f7ebb7-5e9d-4af9-aff9-30e7d89ceb63': {
    // Slack
    parent: '7c637048-99f5-4899-8dc2-97b13d541a74',
    parentFormatted: {
      name: 'Salesforce',
      id: '7c637048-99f5-4899-8dc2-97b13d541a74',
      accounts: [
        {
          name: 'SALESFORCE.COM INC',
          value: {
            6: 557350.65,
            12: 3615843.78,
            60: 6151654.82,
            max: 6151654.82,
          },
          description:
            'Salesforce.com Inc. provides software including Sales Cloud, CPQ, and Tableau, and professional services such as consulting and training.',
          categories: [
            'CRM Software',
            'Professional Services',
            'Subscription Software',
          ],
        },
      ],
    },
    children: [],
    siblings: [],
    supplierIds: ['7c637048-99f5-4899-8dc2-97b13d541a74'],
  },
  '7241cd45-29a8-4ded-943d-5496fa3fc846': {
    // Atlassian
    parent: null,
    children: ['35a5792e-1393-44a0-8463-373f43c86662'],
    childrenFormatted: [
      {
        name: 'Loom',
        id: '35a5792e-1393-44a0-8463-373f43c86662',
        accounts: [
          {
            name: 'Loom, Inc',
            value: {
              6: 24796.8,
              12: 40564.8,
              60: 40564.8,
              max: 40564.8,
            },
            description:
              'Loom is used for asynchronous communication, video recording, presentations, and collaboration.',
            categories: ['Video Conferencing', 'Asynchronous Communication'],
          },
        ],
      },
    ],
    siblings: [],
    supplierIds: ['35a5792e-1393-44a0-8463-373f43c86662'],
  },
  '35a5792e-1393-44a0-8463-373f43c86662': {
    // Loom
    parent: '7241cd45-29a8-4ded-943d-5496fa3fc846',
    parentFormatted: {
      name: 'Atlassian',
      id: '7241cd45-29a8-4ded-943d-5496fa3fc846',
      accounts: [
        {
          name: 'Atlassian',
          id: '7241cd45-29a8-4ded-943d-5496fa3fc846',
          accounts: [
            {
              name: 'Atlassian US LLC - dba Atlassian PTY LTD',
              value: {
                6: 0,
                12: 7793.55,
                60: 7793.55,
                max: 7793.55,
              },
              description:
                'Atlassian provides software for collaboration and productivity, including add-ons for Confluence and Jira, and a test case management system called Xray.',
              categories: [
                'Collaboration software',
                'Confluence add-ons',
                'Test management software',
              ],
            },
          ],
        },
      ],
    },
    children: [],
    siblings: [],
    supplierIds: ['7241cd45-29a8-4ded-943d-5496fa3fc846'],
  },
};
