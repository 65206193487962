import {
  Box,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BoldText, ResultsBox } from '../../../common/Blocks';
import ClearFiltersButton from '../../../common/ClearFilters';
import { appSlice } from '../../../store/appSlice';
import { encodeQuery } from '../../../store/storeFunctions';
import BackofficeTabs, { BACKOFFICE_TABS } from '../BackofficeTabs';
import AlternativeManualLabel from './AlternativeManualLabel';

const Alternatives = ({ onTabChange }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [totalItemsText, setTotalItemsText] = useState();

  const { filtersBundles, backofficeAlternatives } = useSelector(
    (state) => state.appSlice,
  );

  const { setBackofficeAlternatives } = appSlice.actions;

  useEffect(() => {
    setCurrentPage(1);
    getAlternatives().then(processAlternatives);
  }, [filtersBundles]);

  useEffect(() => {
    getAlternatives().then(processAlternatives);
  }, [currentPage]);

  const metaTotalCount = (meta) => {
    return meta?.total_count;
  };

  const calculateItemsText = (meta) => {
    const entity = 'external alternative';
    const totalCount = metaTotalCount(meta);
    return `${totalCount} ${totalCount === 1 ? entity : `${entity}s`} found`;
  };

  const getAlternatives = async () => {
    const query = {
      page: currentPage - 1,
      organization_id: filtersBundles.organizationId,
      supplier_id: filtersBundles.supplierId,
      labeled: filtersBundles.labeled,
    };

    const apiUrl = `${process.env.REACT_APP_BACKOFFICE_BASE_URL}/external_supplier_alternatives`;
    const queryString = encodeQuery(query);
    const urlWithQuery = `${apiUrl}?${queryString}`;
    const response = await fetch(urlWithQuery, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
      credentials: 'include',
    });
    if (response.ok) {
      return await response.json();
    } else {
      return 'error';
    }
  };

  const onSave = (updatedAlternative) => {
    const updatedAlternatives = backofficeAlternatives.map((alternative) => {
      if (updatedAlternative.id === alternative.id) return updatedAlternative;
      return alternative;
    });
    dispatch(setBackofficeAlternatives(updatedAlternatives));
  };

  const processAlternatives = (response) => {
    if (response !== 'error') {
      setPagination(response?.meta || {});
      dispatch(setBackofficeAlternatives(response?.alternatives || []));
      setTotalItemsText(calculateItemsText(response?.meta || {}));
    }
    setIsLoading(false);
  };

  const handlePageChange = (_, value) => {
    setCurrentPage(value);
  };

  const tableHeaders = ['Organization', 'Supplier', 'Company', 'Website', 'Labeled'];

  return (
    <Box width={'100%'}>
      <Box
        marginBottom={'24px'}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'flex-end'}
      >
        <BackofficeTabs
          currentTab={BACKOFFICE_TABS.ALTERNATIVES}
          handleChangeTab={onTabChange}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '16px',
        }}
      >
        <Box>
          {isLoading ? (
            <Box height={'36px'}>&nbsp;</Box>
          ) : (
            <ResultsBox>
              <BoldText>{totalItemsText}</BoldText>
              <ClearFiltersButton
                variant={'filtersBundles'}
              ></ClearFiltersButton>
            </ResultsBox>
          )}
        </Box>
      </Box>
      <Stack marginTop={'16px'} alignItems={'flex-end'}>
        {!isLoading && Boolean(backofficeAlternatives.length) && (
          <Pagination
            page={currentPage}
            onChange={handlePageChange}
            count={pagination?.total_pages}
            color="primary"
          />
        )}
      </Stack>
      <Table
        sx={{
          borderCollapse: 'separate !important',
          border: '1px solid #DEDBEC',
          borderRadius: '12px',
          width: '100%',
          tableLayout: 'auto',
        }}
      >
        <TableHead>
          <TableRow>
            {tableHeaders.map((label) => (
              <TableCell key={label}>
                <Box
                  color={(theme) => theme.palette.text.main}
                  fontSize={(theme) => theme.typography.subtitle2}
                >
                  {label}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {backofficeAlternatives?.map((alternative) => (
            <TableRow
              key={alternative.id}
            >
              <TableCell>
                <Box
                  color={(theme) => theme.palette.text.medium}
                  fontSize={(theme) => theme.typography.caption}
                >
                  {alternative.organization_id}
                </Box>
              </TableCell>
              <TableCell>
                <Box
                  color={(theme) => theme.palette.text.medium}
                  fontSize={(theme) => theme.typography.caption}
                >
                  {alternative.supplier_id}
                </Box>
              </TableCell>
              <TableCell>
                <Box
                  color={(theme) => theme.palette.text.medium}
                  fontSize={(theme) => theme.typography.caption}
                >
                  {alternative.company_name}
                </Box>
              </TableCell>
              <TableCell>
                <Box
                  color={(theme) => theme.palette.text.medium}
                  fontSize={(theme) => theme.typography.caption}
                >
                  {alternative.website}
                </Box>
              </TableCell>
              <TableCell>
                <Box
                  color={(theme) => theme.palette.text.medium}
                  fontSize={(theme) => theme.typography.caption}
                >
                  <AlternativeManualLabel alternative={alternative} onSave={onSave} />
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Stack marginTop={'16px'} alignItems={'flex-end'}>
        {!isLoading && Boolean(backofficeAlternatives.length) && (
          <Pagination
            page={currentPage}
            onChange={handlePageChange}
            count={pagination?.total_pages}
            color="primary"
          />
        )}
      </Stack>
    </Box>
  );
};

export default Alternatives;
