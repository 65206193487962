import { Box, IconButton, Modal, Stack, Table, TableBody } from '@mui/material';
import React, { useEffect, useState } from 'react';
import StyledIcon from '../../common/StyledIcon';
import { CloseRounded } from '@mui/icons-material';
import { InnerTable } from './ReportTable';
import LoadingSkeleton from '../../common/LoadingSkeleton';
import {
  calculateSupplierDrillDownColumns,
  generateQueryParams,
  SUPPLIER_DRILL_DOWN_HEADERS,
} from './reportHelpers';
import { encodeQuery } from '../../store/storeFunctions';
import { FetchWithCancel } from '../../common/FetchWithCancel';

const ReportTableSupplierDrillDownModal = ({
  open,
  handleClose,
  options,
  item,
  filters,
}) => {
  const [collection, setCollection] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getStats = () => {
    const query = {
      pivot: options.pivot,
      value: item[options.key],
      ...generateQueryParams(filters, 'MM/DD/YYYY'),
    };

    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/report/supplier_spend`;
    const queryString = encodeQuery(query);
    const urlWithQuery = `${apiUrl}?${queryString}`;
    return FetchWithCancel(urlWithQuery);
  };

  useEffect(() => {
    if (!item || !item[options.key]) return;

    setIsLoading(true);

    const { fetchPromise: supplierPromise, cancel: supplierCancel } =
      getStats();

    supplierPromise.then((supplierData) => {
      if (supplierData !== 'error') {
        const suppliers = calculateSupplierDrillDownColumns(
          supplierData?.suppliers || [],
        );
        setCollection(suppliers);
      } else {
        setCollection([]);
      }
      setIsLoading(false);
    });

    return () => {
      supplierCancel && supplierCancel();
    };
  }, [item]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          width: 'fit-content',
          minWidth: '830px',
          maxWidth: '1080px',
          maxHeight: '640px',
          background: 'white',
          position: 'absolute',
          marginTop: '35px',
          top: '0',
          left: 'calc(50% - 415px)',
          borderRadius: '12px',
          padding: '24px',
          overflow: 'auto',
        }}
      >
        <Stack
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box
            color={(theme) => theme.palette.text.main}
            fontSize={(theme) => theme.typography.h6}
          >
            Unique suppliers
          </Box>
          <IconButton padding={'8px'} onClick={handleClose}>
            <StyledIcon component={CloseRounded} color={'icon'} />
          </IconButton>
        </Stack>
        <Stack
          color={'#0F0030'}
          fontSize={(theme) => theme.typography.subtitle2}
          gap={'8px'}
          width={'100%'}
          padding={'16px 0'}
          borderBottom={'1.5px solid #694FA31A'}
        >
          <Box display={'flex'} gap={'16px'}>
            <Box width={'160px'}>Total unique suppliers</Box>{' '}
            <Box fontWeight={'400'}>{item.unique_suppliers}</Box>
          </Box>
          <Box display={'flex'} gap={'16px'}>
            <Box width={'160px'}>Total amount</Box>{' '}
            <Box fontWeight={'400'}>{item.total_spend_amount_usd}</Box>
          </Box>
          <Box display={'flex'} gap={'16px'}>
            <Box width={'160px'}>Average amount</Box>{' '}
            <Box textAlign={'right'} fontWeight={'400'}>
              {item.avg_total_spend_amount_usd}
            </Box>
          </Box>
        </Stack>
        {isLoading ? (
          <Table>
            <TableBody>
              <LoadingSkeleton variant={'adminLogs'} />
            </TableBody>
          </Table>
        ) : (
          <InnerTable
            collection={collection}
            tableHeaders={SUPPLIER_DRILL_DOWN_HEADERS}
            options={{
              type: 'supplier',
            }}
            allowSortBy={SUPPLIER_DRILL_DOWN_HEADERS[0]?.sort}
          />
        )}
      </Box>
    </Modal>
  );
};

export default ReportTableSupplierDrillDownModal;
