import { CloseRounded } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Stack,
} from '@mui/material';
import React from 'react';
import DetailsNavigation from '../../common/DetailsNavigation';
import ShareLink from '../../common/ShareLink';
import Header from '../Header';
import SupplierAlternatives from './SupplierAlternatives';


const sidebarStyle = {
  minHeight: 'calc(100vh - 32px)',
  maxHeight: 'calc(100vh - 32px)',
  overflowY: 'auto',
  padding: '0',
  background: 'white',
  '@media (max-width: 768px)': {
    width: 'calc(100vw)',
  },
};

const SupplierAlternativeFullscreen = ({
  type,
  close,
  supplier,
  variant,
  handleClick,
}) => {

  return (
    <Box sx={variant === 'sidebar' ? sidebarStyle : {}}>
      {!Boolean(variant === 'sidebar') && (
        <Box>
          <Header handleOnClick={close} />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        <Box display={'flex'} flexDirection={'column'}>
          <Stack
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{
              padding: variant === 'sidebar' ? '16px' : '16px 24px 0 120px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
              }}
            >
              <DetailsNavigation handleClose={close} />
              <Box
                color={(theme) => theme.palette.text.main}
                fontSize={(theme) => theme.typography.subtitle1}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                }}
              >
                <Box>Suppliers</Box>
                <Box> / {supplier.name}</Box>
                <Box color={(theme) => theme.palette.text.secondary}>
                  {' '}
                  / Alternatives
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', gap: '24px' }}>
              <ShareLink />
              <IconButton type="button" sx={{ padding: '8px' }} onClick={close}>
                <CloseRounded sx={{ color: '#2F236D73', fontSize: 24 }} />
              </IconButton>
            </Box>
          </Stack>
        </Box>
        <Box
          sx={
            variant === 'sidebar'
              ? { padding: '16px' }
              : { paddingLeft: '120px', paddingBottom: '24px', width: '66%' }
          }
        >
          <SupplierAlternatives
            supplierId={supplier.id}
            variant={variant}
            handleClick={handleClick}
            fullscreen={type}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SupplierAlternativeFullscreen;
