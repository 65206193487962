import { CloseRounded, LockOutlined } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, IconButton, Link, Stack, Tab, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import DetailsNavigation from '../../common/DetailsNavigation';
import { ReactComponent as CloseFullscreen } from '../../common/icons/closeFullscreen.svg';
import LoadingSkeleton from '../../common/LoadingSkeleton';
import Logo from '../../common/Logo';
import NoResultCard from '../../common/NoResultCard';
import ShareLink from '../../common/ShareLink';
import StyledIcon from '../../common/StyledIcon';
import SupplierBadges from '../../common/SupplierBadges';
import SupplierDetailsCompanyDetails from '../../common/SupplierDetailsCompanyDetails';
import { appSlice } from '../../store/appSlice';
import { supplierResponseTime } from '../../utils/functions';
import Header from '../Header';
import InteractionEmployeeContacts from '../interactionDetails/InteractionEmployeeContacts';
import SupplierActivities from './SupplierActivities';
import SupplierActivityFullscreen from './SupplierActivityFullscreen';
import SupplierActivitySummary from './SupplierActivitySummary';
import SupplierAlternativeFullscreen from './SupplierAlternativeFullscreen';
import SupplierAlternatives from './SupplierAlternatives';
import SupplierContracts from './SupplierContracts';
import SupplierDetailsAISummary from './SupplierDetailsAISummary';
import SupplierDetailsContacts from './SupplierDetailsContacts';
import SupplierDetailsSpend from './SupplierDetailsSpend';
import SupplierDetailsStructure from './SupplierDetailsStructure';
import SupplierDetailsTeams from './SupplierDetailsTeams';

const SupplierDetailsFullscreen = ({ close }) => {
  const [searchParams] = useSearchParams();

  const hasDetailedActivityType = searchParams.get('activityType');
  const hasDetailedAlternativeType = searchParams.get('alternativeType');
  const { id, pageId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { previousSearch, page } = useSelector((state) => state.appSlice);

  const {
    addPreviousDetailsNavigation,
    setNextDetailsNavigation,
    setPage,
    setSearch,
    setPreviousSearch,
  } = appSlice.actions;

  const [supplier, setSupplier] = useState();
  const [responseTime, setRepsonseTime] = useState();
  const [interactions, setInteractions] = useState([]);
  const [combinedInteraction, setCombinedInteraction] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [supplierDetailsTab, setSupplierDetailsTab] = useState('1');
  const [invoiceCollection, setInvoiceCollection] = useState();
  const [purchaseOrderCollection, setPurchaseOrderCollection] = useState();
  const [requestCollection, setRequestCollection] = useState();

  const handleDetailsTabChange = (_, newValue) => {
    handleDetailsNavigation();
    navigate(
      `${
        page === 'overview' ? '' : `/${page}`
      }/details/supplier/${id}/fullscreen/${newValue}`,
    );
  };

  const handleDetailsNavigation = () => {
    dispatch(addPreviousDetailsNavigation(location.pathname));
    dispatch(setNextDetailsNavigation([]));
  };

  useEffect(() => {
    if (pageId) {
      setSupplierDetailsTab(pageId);
    } else {
      setSupplierDetailsTab('1');
    }
  }, [pageId]);

  useEffect(() => {
    setIsLoading(true);
    getData().then((res) => {
      if (res !== 'error') {
        setSupplier(res);
        setRepsonseTime(supplierResponseTime(res));
        const interactionIds = [...(res.interactions || [])].map(
          (item) => item.id,
        );
        getInteractions(interactionIds).then((data) => {
          setInteractions(data);
          setIsLoading(false);
        });
      } else {
        setIsLoading(false);
      }
    });
    getData('invoices').then((res) => {
      if (res !== 'error') {
        setInvoiceCollection(res);
      } else {
        setInvoiceCollection([]);
      }
    });
    getData('purchase_orders').then((res) => {
      if (res !== 'error') {
        setPurchaseOrderCollection(res);
      } else {
        setPurchaseOrderCollection([]);
      }
    });
    getData('requests').then((res) => {
      if (res !== 'error') {
        setRequestCollection(res);
      } else {
        setRequestCollection([]);
      }
    });
  }, [id]);

  useEffect(() => {
    if (!supplier) return;
    const filteredStakeholders = filterInternalStakeholders(
      supplier.stakeholders || [],
    );
    if (filteredStakeholders.length === 0) {
      setCombinedInteraction(null);
      return;
    }

    const reducedInteraction = {
      internal_stakeholders: filteredStakeholders,
    };
    setCombinedInteraction(reducedInteraction);
  }, [supplier]);

  const filterInternalStakeholders = (res) => {
    const employees = res.filter((item) => item.department);
    const employeeDepartments = [
      ...new Set(employees.map((item) => item.department)),
    ].sort((a, b) => a?.localeCompare(b));
    return employeeDepartments
      .map((item) => ({
        type: item,
        items: res
          .filter((internal) => internal.department === item)
          .map((item) => ({
            ...item,
            id: item.email,
          })),
      }))
      .filter((item) => item.items.length !== 0);
  };

  const getInteractions = async (interactionIds) => {
    return await Promise.all(interactionIds.map((id) => getInteraction(id)));
  };

  const getData = async (dataType) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/suppliers/${id}/${dataType || ''}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
        credentials: 'include',
      },
    );
    if (response.ok) {
      return await response.json();
    } else {
      return 'error';
    }
  };

  const getInteraction = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/interactions/${id}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
        credentials: 'include',
      },
    );
    if (response.ok) {
      return await response.json();
    } else {
      return 'error';
    }
  };

  const statsDepartments = [
    ...(supplier?.financial_stats?.departments || []),
    ...(supplier?.workflow_stats?.departments || []),
  ];
  const allDepartmentsInvolved = statsDepartments?.length
    ? statsDepartments
    : interactions
        ?.map((interaction) => interaction.departments_involved)
        .flat();
  const departmentsInvolved = [...new Set(allDepartmentsInvolved)].sort(
    (a, b) => a.localeCompare(b),
  );

  const hasCategoryChips = () => {
    return Boolean(supplier?.categories && supplier.categories.length !== 0);
  };

  const hidePoweredBy = () => {
    return Boolean(
      !hasCategoryChips() &&
        !supplier.foundation_year &&
        !supplier.min_revenue_estimative &&
        !supplier.num_employees_rang &&
        !supplier.website &&
        !supplier.headquarters &&
        (!supplier.diversity_spotlights ||
          supplier.diversity_spotlights.length === 0),
    );
  };

  const tabStyles = {
    textTransform: 'none',
    minHeight: 0,
    minWidth: 0,
    padding: 0,
    marginRight: '16px',
    paddingBottom: '10px',
    fontSize: '16px',
    '&.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.60)',
      '>.MuiTab-iconWrapper': {
        marginRight: 0,
      },
    },
  };

  if (hasDetailedActivityType) {
    return (
      <Box>
        {!supplier || isLoading ? (
          <LoadingSkeleton variant={'supplierDetailsFullscreen'} />
        ) : (
          <SupplierActivityFullscreen
            type={hasDetailedActivityType}
            supplier={supplier}
            close={close}
            interactions={supplier?.interactions}
            requests={requestCollection}
            handleClick={() => handleDetailsNavigation()}
          />
        )}
      </Box>
    );
  }

  if (hasDetailedAlternativeType) {
    return (
      <Box>
        {!supplier || isLoading ? (
          <LoadingSkeleton variant={'supplierDetailsFullscreen'} />
        ) : (
          <SupplierAlternativeFullscreen
            type={hasDetailedAlternativeType}
            supplier={supplier}
            close={close}
            handleClick={() => handleDetailsNavigation()}
          />
        )}
      </Box>
    );
  }

  return (
    <Box>
      {!supplier || isLoading ? (
        <LoadingSkeleton variant={'supplierDetailsFullscreen'} />
      ) : (
        <Box>
          <Box>
            <Header handleOnClick={close} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <Box display={'flex'} flexDirection={'column'}>
              <Stack
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                sx={{
                  borderBottom: '1.5px solid #694FA31A',
                  padding: '16px 120px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px',
                  }}
                >
                  <DetailsNavigation handleClose={close} />
                  <Box
                    color={(theme) => theme.palette.text.main}
                    fontSize={(theme) => theme.typography.subtitle1}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '4px',
                    }}
                  >
                    <Link
                      color={(theme) => theme.palette.text.main}
                      fontSize={(theme) => theme.typography.subtitle1}
                      sx={{ cursor: 'pointer', fontWeight: '500' }}
                      underline="none"
                      onClick={() => {
                        dispatch(setPage('suppliers'));
                        window.scrollTo(0, 0);
                        close();
                        navigate('/suppliers');
                      }}
                    >
                      Suppliers
                    </Link>
                    <Box color={(theme) => theme.palette.text.secondary}>
                      {' '}
                      / {supplier.name}
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', gap: '24px' }}>
                  <ShareLink />
                  <IconButton
                    type="button"
                    sx={{ padding: '0' }}
                    onClick={() => {
                      dispatch(setSearch(previousSearch));
                      dispatch(setPreviousSearch(''));
                      handleDetailsNavigation();
                      navigate(
                        `${
                          page === 'overview' ? '' : `/${page}`
                        }/details/supplier/${id}/${pageId || ''}`,
                      );
                    }}
                  >
                    <StyledIcon component={CloseFullscreen} color={'icon'} />
                  </IconButton>
                  <IconButton
                    type="button"
                    sx={{ padding: '8px' }}
                    onClick={close}
                  >
                    <CloseRounded sx={{ color: '#2F236D73', fontSize: 24 }} />
                  </IconButton>
                </Box>
              </Stack>
              <Box
                display={'flex'}
                flexDirection={'column'}
                gap={'10px'}
                padding={'16px 120px'}
              >
                <Box display={'flex'} justifyContent={'space-between'}>
                  <Stack
                    flexDirection={'row'}
                    gap={'16px'}
                    alignItems={'center'}
                  >
                    <Logo size={44} img={supplier.image_url} />

                    <Box
                      color={(theme) => theme.palette.text.black}
                      sx={{
                        lineHeight: '150%',
                      }}
                      fontSize={(theme) => theme.typography.h6}
                    >
                      {supplier.name}
                    </Box>
                  </Stack>
                </Box>
                <SupplierDetailsAISummary supplier={supplier} />
                <Box padding={'6px 0'}>
                  <SupplierBadges supplier={supplier} />
                </Box>
                <TabContext value={supplierDetailsTab}>
                  <Box
                    sx={{
                      padding: '0 120px',
                      borderBottom: 0,
                      borderBottom: '1px solid rgba(101, 88, 177, 0.1)',
                      margin: '0 -120px 6px',
                    }}
                  >
                    <TabList
                      onChange={handleDetailsTabChange}
                      textColor="secondary"
                      indicatorColor="secondary"
                      aria-label="supplier details tabs"
                      sx={{ minHeight: '0' }}
                    >
                      <Tab sx={tabStyles} label="Summary" value="1" />
                      <Tab sx={tabStyles} label="People" value="2" />
                      <Tab sx={tabStyles} label="Activities" value="3" />
                      <Tab sx={tabStyles} label="Contracts" value="4" />
                      <Tab sx={tabStyles} label="Structure & spend" value="5" />
                      <Tab sx={tabStyles} label="Alternatives" value="6" />
                    </TabList>
                  </Box>
                  <TabPanel sx={{ padding: 0 }} value="1">
                    <Box
                      display={'flex'}
                      gap={'16px'}
                      paddingBottom={'32px'}
                      color={(theme) => theme.palette.text.main}
                    >
                      <Stack width={'66%'} gap={'16px'}>
                        {(!Boolean(hidePoweredBy()) ||
                          supplier.annotated_perplexity) && (
                          <SupplierDetailsCompanyDetails supplier={supplier} />
                        )}
                        {Boolean(invoiceCollection?.length) && (
                          <Box marginBottom={'16px'} width={'100%'}>
                            <SupplierDetailsStructure
                              invoices={invoiceCollection}
                              timeframe={12}
                              supplierName={supplier.name}
                              supplierId={supplier.id}
                              handleClick={() => handleDetailsNavigation()}
                              summary={() => {
                                handleDetailsNavigation();
                                handleDetailsTabChange('', '5');
                              }}
                            />
                          </Box>
                        )}
                        {Boolean(
                          supplier?.interactions?.length ||
                            requestCollection?.length,
                        ) && (
                          <SupplierActivitySummary
                            interactions={supplier?.interactions}
                            requests={requestCollection}
                            handleClick={() => {
                              handleDetailsTabChange('', '3');
                            }}
                          />
                        )}
                      </Stack>
                      <Stack width={'33%'} gap={'16px'}>
                        {Boolean(departmentsInvolved.length) && (
                          <SupplierDetailsTeams
                            teams={departmentsInvolved}
                            handleClick={() => {
                              handleDetailsTabChange('', '2');
                            }}
                          />
                        )}
                        {Boolean(supplier?.contacts?.length) && (
                          <SupplierDetailsContacts
                            supplier={supplier}
                            responseTime={responseTime}
                            miniView={() => {
                              handleDetailsTabChange('', '2');
                            }}
                          />
                        )}
                      </Stack>
                    </Box>
                  </TabPanel>
                  <TabPanel sx={{ padding: 0 }} value="2">
                    {Boolean(
                      supplier?.stakeholders?.length ||
                        supplier?.contacts?.length,
                    ) ? (
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        width={'66%'}
                      >
                        {combinedInteraction && (
                          <InteractionEmployeeContacts
                            interaction={combinedInteraction}
                            supplierView
                          />
                        )}
                        {supplier?.contacts?.length > 0 && (
                          <SupplierDetailsContacts
                            supplier={supplier}
                            responseTime={responseTime}
                          />
                        )}
                      </Box>
                    ) : (
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        width={'66%'}
                      >
                        <NoResultCard variant="supplier-people" />
                      </Box>
                    )}
                  </TabPanel>
                  <TabPanel sx={{ padding: 0 }} value="3">
                    <Box width={'66%'}>
                      <SupplierActivities
                        handleClick={() => handleDetailsNavigation()}
                        interactions={supplier?.interactions || []}
                        requests={requestCollection || []}
                      />
                    </Box>
                  </TabPanel>
                  <TabPanel sx={{ padding: 0 }} value="4">
                    <Box width={'66%'}>
                      <SupplierContracts supplierId={supplier.id} />
                    </Box>
                  </TabPanel>
                  <TabPanel sx={{ padding: 0 }} value="5">
                    <Box>
                      {Boolean(
                        invoiceCollection && purchaseOrderCollection,
                      ) && (
                        <SupplierDetailsSpend
                          invoices={invoiceCollection}
                          purchase_orders={purchaseOrderCollection}
                          supplierName={supplier.name}
                          supplierId={supplier.id}
                          handleClick={() => handleDetailsNavigation()}
                        />
                      )}
                    </Box>
                  </TabPanel>
                  <TabPanel sx={{ padding: 0 }} value="6">
                    <Box width={'66%'}>
                      <SupplierAlternatives
                        supplierId={supplier.id}
                        handleClick={() => handleDetailsNavigation()}
                      />
                    </Box>
                  </TabPanel>
                </TabContext>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SupplierDetailsFullscreen;
