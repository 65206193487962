import { Drawer } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { appSlice } from '../store/appSlice';
import InteractionDetails from './interactionDetails/InteractionDetails';
import SupplierDetails from './supplierDetails/SupplierDetails';
import SupplierDetailsFullscreen from './supplierDetails/SupplierDetailsFullscreen';

const Details = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { setSearch, setPreviousSearch, clearDetailsNavigation } =
    appSlice.actions;
  const { page, search, previousSearch } = useSelector(
    (state) => state.appSlice,
  );

  const [isOpen, setIsOpen] = useState(false);
  const [useSupplierFullscreen, setUseSupplierFullscreen] = useState(false);

  useEffect(() => {
    document.title = 'PartnerElement | Details';

    const isFullscreenView = location.pathname.includes('fullscreen');
    setUseSupplierFullscreen(isFullscreenView);

    if (!isFullscreenView && previousSearch) {
      dispatch(setSearch(previousSearch));
      dispatch(setPreviousSearch(''));
    }

    setTimeout(() => {
      setIsOpen(true);
    }, 300);
  }, [location]);

  const handleClose = (syntheticClick, newPage) => {
    if (!syntheticClick && !newPage) return;
    setIsOpen(false);
    dispatch(clearDetailsNavigation());
    searchParams.delete('activityType');
    searchParams.delete('alternativeType');

    if (syntheticClick?.currentTarget && previousSearch && !search) {
      dispatch(setSearch(previousSearch));
    }
    dispatch(setPreviousSearch(''));
    document.title = `PartnerElement | ${
      page?.charAt(0).toUpperCase() + page?.slice(1)
    }`;
    setTimeout(() => {
      if (page === 'overview') {
        navigate('/');
      } else if (
        !newPage ||
        newPage === 'backdropClick' ||
        newPage === 'escapeKeyDown'
      ) {
        navigate(`/${page}?${searchParams}`);
      }
    }, 300);
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        component: 'div',
        sx: {
          overflowX: 'hidden',
          width: useSupplierFullscreen ? '100%' : '768px',
          top: '0',
        },
      }}
    >
      <AnimatePresence mode={'sync'}>
        <Routes location={location}>
          <Route
            path="supplier/:id/fullscreen/:pageId?"
            element={
              <motion.div
                key="supplier"
                initial={{ opacity: 0, x: '100%' }}
                animate={{ opacity: 1, x: '0%' }}
                exit={{ opacity: 0, x: '-100%' }}
                transition={{ duration: 0.3 }}
              >
                <SupplierDetailsFullscreen close={handleClose} />
              </motion.div>
            }
          />
          <Route
            path="supplier/:id/:pageId?"
            element={
              <motion.div
                key="supplier"
                initial={{ opacity: 0, x: '100%' }}
                animate={{ opacity: 1, x: '0%' }}
                exit={{ opacity: 0, x: '-100%' }}
                transition={{ duration: 0.3 }}
              >
                <SupplierDetails close={handleClose} />
              </motion.div>
            }
          />
          <Route
            path="interaction/:id/fullscreen"
            element={
              <motion.div
                key="interaction"
                initial={{ opacity: 0, x: '100%' }}
                animate={{ opacity: 1, x: '0%' }}
                exit={{ opacity: 0, x: '-100%' }}
                transition={{ duration: 0.3 }}
              >
                <InteractionDetails close={handleClose} fullscreen={true} />
              </motion.div>
            }
          />
          <Route
            path="interaction/:id"
            element={
              <motion.div
                key="interaction"
                initial={{ opacity: 0, x: '100%' }}
                animate={{ opacity: 1, x: '0%' }}
                exit={{ opacity: 0, x: '-100%' }}
                transition={{ duration: 0.3 }}
              >
                <InteractionDetails close={handleClose} />
              </motion.div>
            }
          />
        </Routes>
      </AnimatePresence>
    </Drawer>
  );
};

export default Details;
