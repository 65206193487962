import {
  CloseRounded,
  ContentCopyRounded,
  ForwardToInboxRounded,
} from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { AnimatePresence, motion } from "framer-motion";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ConfirmCopy from "../../common/ConfirmCopy";
import StyledIcon from '../../common/StyledIcon';
import TeamNameWithIcon from '../../common/TeamNameWithIcon';

const InteractionEmployeeContacts = ({ interaction, supplierView }) => {
  const [openEngage, setOpenEngage] = useState(null);
  const [emailTemplate, setEmailTemplate] = useState('');
  const [engageText, setEngageText] = useState('');
  const [engage, setEngage] = useState([]);
  const [confirmCopy, setConfirmCopy] = useState('id + variant');

  const handleConfirmCopy = (id, variant) => {
    setConfirmCopy(`${id} ${variant}`);
    setTimeout(() => {
      setConfirmCopy('');
    }, 1000);
  };

  const getTemplates = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/interactions/${interaction.id}/templates`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
        credentials: 'include',
      },
    );
    if (response.ok) {
      return await response.json();
    } else {
      return 'error';
    }
  };

  const setDefaultTemplate = (templates) => {
    const defaultTemplate = templates.find(
      (template) => template.default_template,
    );
    if (defaultTemplate) {
      setEmailTemplate(defaultTemplate.title);
      setEngageText(defaultTemplate.text);
    }
  };

  const fetchTemplates = async () => {
    const engage = await getTemplates();
    if (engage !== 'error') {
      setEngage(engage);
    }
    setDefaultTemplate(engage);
  };

  useEffect(() => {
    if (supplierView) return;
    fetchTemplates();
  }, []);

  return (
    <Box
      sx={{
        border: '1px solid #694FA31A',
        borderRadius: '12px',
        marginBottom: '24px',
        width: '100%',
      }}
    >
      <Box
        sx={{
          padding: '16px',
          paddingBottom: '0',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            color={(theme) => theme.palette.text.main}
            fontSize={(theme) => theme.typography.h6}
          >
            Employees
          </Box>
          <Box sx={{ position: 'relative' }}>
            {!supplierView && (
              <Button
                onClick={(event) => setOpenEngage(event.currentTarget)}
                variant={'contained'}
                color={'secondary'}
                sx={{ borderRadius: '10px' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'end',
                    gap: '8px',
                  }}
                >
                  <StyledIcon component={ForwardToInboxRounded} />
                  <Box
                    sx={{
                      lineHeight: '24px',
                      textTransform: 'none',
                      fontSize: '14px',
                      fontWeight: '500',
                    }}
                  >
                    Engage
                  </Box>
                </Box>
              </Button>
            )}
            <AnimatePresence>
              {Boolean(openEngage) && (
                <Box
                  sx={{
                    position: 'absolute',
                    zIndex: '1',
                    top: '36px',
                    boxShadow:
                      '0px 3px 5px -1px rgba(0, 0, 0, 0.20), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
                    right: '0',
                    width: '424px',
                    borderRadius: '12px',
                    background: 'white',
                    padding: '12px',
                    maxHeight: '500px',
                    overflowY: 'auto',
                    pointerEvents: 'all',
                    ul: { padding: '0' },
                    [`@media (max-height: 600px)`]: {
                      maxHeight: '300px',
                    },
                  }}
                >
                  <motion.div
                    initial={{ opacity: 0, scale: 0.95 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.95 }}
                    transition={{ duration: 0.2 }}
                    className="dropmenu"
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: '4px',
                      }}
                    >
                      <Box
                        color={(theme) => theme.palette.text.black}
                        fontSize={(theme) => theme.typography.subtitle1}
                      >
                        Engage
                      </Box>
                      <Box>
                        <IconButton
                          onClick={() => setOpenEngage(null)}
                          sx={{ padding: 0 }}
                        >
                          <StyledIcon component={CloseRounded} color={'icon'} />
                        </IconButton>
                      </Box>
                    </Box>
                    <Box
                      sx={{ marginBottom: '24px' }}
                      color={(theme) => theme.palette.text.caption}
                    >
                      Reach out to fellow employees to offer help with easy and
                      effective templates.
                    </Box>
                    <Box sx={{ marginBottom: '24px' }}>
                      <FormControl sx={{ width: '100%' }}>
                        <InputLabel htmlFor="emailTemplate" color={'secondary'}>
                          Email template
                        </InputLabel>
                        <Select
                          sx={{
                            borderRadius: '10px',
                          }}
                          id={'emailTemplate'}
                          label={'Email template'}
                          value={emailTemplate}
                          color={'secondary'}
                          onChange={(event) => {
                            setEmailTemplate(event.target.value);
                            const findEngageItem = engage.find(
                              (item) => item.title === event.target.value,
                            );
                            setEngageText(
                              findEngageItem ? findEngageItem.text : '',
                            );
                          }}
                        >
                          {engage.map((item) => (
                            <MenuItem value={item.title} key={item.title}>
                              {item.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box sx={{ marginBottom: '24px' }}>
                      <FormControl sx={{ width: '100%' }}>
                        <TextField
                          sx={{ fieldset: { borderRadius: '10px' } }}
                          maxRows={12}
                          minRows={5}
                          multiline={true}
                          id={'text'}
                          label={'Select a template'}
                          value={engageText}
                          color={'secondary'}
                          onChange={() => null}
                        />
                      </FormControl>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                      <Box sx={{ position: 'relative' }}>
                        <Button
                          onClick={() => {
                            navigator.clipboard.writeText(engageText);
                            handleConfirmCopy('engage', '');
                          }}
                          sx={{
                            display: 'flex',
                            textTransform: 'none',
                            color: '#694FA3',
                            gap: '8px',
                            padding: '8px',
                          }}
                          variant={'text'}
                        >
                          <StyledIcon component={ContentCopyRounded} />
                          <Box sx={{ fontSize: '14px', fontWeight: '500' }}>
                            Copy
                          </Box>
                        </Button>
                        {confirmCopy === 'engage ' && (
                          <ConfirmCopy
                            message={'Template copied'}
                            positionStyles={{
                              marginTop: '-40px',
                              marginLeft: '-20px',
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                  </motion.div>
                </Box>
              )}
            </AnimatePresence>
          </Box>
        </Box>
      </Box>
      <Box>
        {interaction?.internal_stakeholders.map((internal, index) => (
          <Box key={internal.type}>
            <Box
              sx={{
                borderTop: index !== 0 && '1.5px solid #694FA31A',
                borderBottom: '1.5px solid #694FA31A',
                padding: index === 0 ? '16px' : '24px 16px 16px',
              }}
            >
              <TeamNameWithIcon teamName={internal.type || 'Unknown'} large />
            </Box>
            <Box sx={{ overflowX: 'auto' }}>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      borderBottom:
                        internal.items?.length === 0
                          ? 'none'
                          : '1.5px solid rgba(101, 88, 177, 0.07)',
                      th: {
                        border: 'none',
                        padding: '16px',
                        paddingRight: '0',
                        fontWeight: '500',
                        fontSize: '14px',
                        color: '#000000DE',
                      },
                    }}
                  >
                    <TableCell>
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'nowrap',
                          gap: '8px',
                          alignItems: 'center',
                        }}
                      >
                        Name
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'nowrap',
                          gap: '8px',
                          alignItems: 'center',
                        }}
                      >
                        Title
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'nowrap',
                          gap: '8px',
                          alignItems: 'center',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        Messages
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'nowrap',
                          gap: '8px',
                          alignItems: 'center',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        Last active
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {internal.items.map((item) => (
                    <TableRow
                      key={item.id}
                      sx={{
                        borderBottom: '1.5px solid #694FA31A',
                        td: {
                          border: 'none',
                          padding: '16px',
                          paddingRight: '0',
                          color: '#000000DE',
                          fontSize: '14px',
                          whiteSpace: 'nowrap',
                        },
                        ':last-of-type': { borderBottom: 'none' },
                      }}
                    >
                      <TableCell>{item.name}</TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            maxWidth: '150px',
                            whiteSpace: 'normal',
                          }}
                        >
                          {item.title}
                        </Box>
                      </TableCell>
                      <TableCell>{item.num_emails_total}</TableCell>
                      <TableCell>
                        {Boolean(item.last_active_at) && moment(item.last_active_at).format('MMM DD, YYYY')}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default InteractionEmployeeContacts;
